.login-content {
  height: 100%;
  width: 100%;
  display: flex;
  //align-items: center;
  justify-content: center;
  padding-top: 10rem;
}
.login-form-container {
  border: 1px solid $table-border-color;
  border-radius: 4px;
  max-width: 500px;

  background-color: $table-bg-camp;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 50px;

  img {
    max-width: 200px;
    margin-bottom: 30px;
  }
}

.login-input-container {
  input,
  input:focus,
  input:not([value=""]) {
    max-width: 500px;
    min-height: 30px;
    min-width: 300px;
    background-color: #f6f6f6;
    border: 1px solid #e8e9ea;
    color: #0f293e;
    outline: none;
    box-shadow: none;
    text-align: center;
    margin: 3px;
    font-size: 16px;
    font-weight: 500;
  }
  input:focus {
    border-color: #86b7fe;
  }
  input::placeholder {
    color: #c8c9ca;
  }
}
.login-btn {
  margin-top: 20px;
  background-color: #0f293e;
  color: #fff;
  border: 1px solid #e8e9ea;
  padding: 10px 30px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: -5px 5px 5px #ddd;
}

.login-btn:hover {
  background-color: #193852;
}

.login-btn:disabled {
  background-color: #c8c9ca;
  color: #f6f6f6;
  cursor: not-allowed;
}
