.store {
  input {
    width: 200px !important;
    text-transform: none !important;
    font-size: 14px !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;
}
