.navbar {
  background-color: #0f293e;
  color: #fff;

  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12%;

  font-weight: 700;
  white-space: nowrap;
  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 3px;
  }

  a:visited {
    color: #fff;
  }
  img {
    width: 30px;
    cursor: pointer;
  }
}

.nav-link-container {
  a {
    border: 2px solid #3d5468;
    background-color: #193851;
    padding: 10px 15px;
  }
}

.nav-link-active {
  border: 2px solid #3d5468;
  color: #0f293e;
  background-color: #f6f6f6;

  /* color: #0f293e !important; */
}

.nav-link-super-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}
.nav-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10vh;
  background-color: #0f293e;
  gap: 10px;
  padding: 10px;

  a {
    color: #3d5468 !important;
    border: none !important;
    background: transparent !important;
  }

  a:hover {
    color: #f6f6f6 !important;
  }
}

.nav-menu-icon,
.navbar-menu-container {
  position: absolute;
  left: 10%;
}
